import * as React from 'react'
import { Helmet } from 'react-helmet'
import { browserHistory } from 'react-router'
import { inject, observer, Provider } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { POSStore } from '../../stores/POSStore'
import { ShiftLogin } from './ShiftLogin'
import { POSNavbar } from './POSNavbar'
import { LoadingAnimation } from '../../components/LoadingAnimation'
import { Routes } from '../../constants/Routes'
import { IDevice, IShift, ILocation, API } from '@getgreenline/homi-shared'
import './index.scss'
import { Track } from '../../Track'

import { setRootAuthToken } from '../../utilities/environment'
import { FeatureToggle } from '../../constants/FeatureToggles'
import { WorldpayStore } from '../../stores/WorldpayStore'
import { WebSocketStore } from '../../stores/WebsocketStore'
import { WebSocketService } from '@getgreenline/websocket'
import { THEME_COLORS } from '@getgreenline/shared'

interface Props {
  currentCompanyStore?: CurrentCompanyStore
  location: any
}

interface State {
  device?: IDevice
  shift?: IShift | null
  location?: ILocation
  posStore?: POSStore
  worldpayStore?: WorldpayStore
  webSocketStore?: WebSocketStore
}

@inject('currentCompanyStore')
@observer
export class POS extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    // POS uses different auth token from the dashboard
    const deviceAuthToken = window.localStorage.getItem('deviceAuthToken')

    if (!deviceAuthToken) {
      browserHistory.push(Routes.LOGIN)
      return
    }

    setRootAuthToken(deviceAuthToken)

    // Get the device matching the auth token
    const device = await API.getCurrentDevice()
      .then((device) => {
        this.setState({ device })
        return device
      })
      .catch(() => {
        // If it doesn't match, kick out to the login screen
        browserHistory.push(Routes.LOGIN)
      })

    // To make subsequent type checking happy
    if (!device) {
      browserHistory.push(Routes.LOGIN)
      return
    }

    // Load company into companyStore
    const company = await this.props
      .currentCompanyStore!.getById(device.companyId)
      .then((company) => {
        this.props.currentCompanyStore!.getLocations()
        return company
      })

    const location = await API.getLocationById(company.id, device.locationId).then((location) => {
      this.setState({ location })
      return location
    })

    const shift = await API.getCurrentShift(company.id, device.id).then((shift) => {
      this.setState({ shift })
      return shift
    })

    const { productStore } = this.props.currentCompanyStore!

    // Assign company on Intercom
    Track.setGroup(company.id, {
      id: company.id,
      name: company.name,
      province: company.province,
      createDate: company.createDate,
    })

    await this.props.currentCompanyStore!.getQueueEnhancementsFeature()

    const canUseWebSocket = await this.props.currentCompanyStore!.isFeatureEnabled(
      FeatureToggle.CAN_USE_WEBSOCKET,
    )

    await this.props.currentCompanyStore?.getCanUseLoyaltyPoints()

    await this.props.currentCompanyStore?.getLoyaltyAsPaymentType()

    const webSocketStore = new WebSocketStore({
      tenantId: company.id,
      retailerId: location.id,
      authorization: deviceAuthToken,
      canUseWebSocket,
      deviceId: device.id,
    })

    this.setState({ webSocketStore })

    const posStore = new POSStore(
      company,
      location,
      device,
      shift,
      productStore!,
      this.props.currentCompanyStore!,
      webSocketStore,
    )

    const discounts = await this.props.currentCompanyStore!.productStore!.getDiscounts({
      locationIds: [posStore.location.id],
      updateDate: null,
    })
    const categories = await this.props.currentCompanyStore!.productStore!.getCategories()

    posStore.currentPayment.setDiscounts(discounts)
    posStore.currentPayment.setCategories(categories)

    const useSearchBar = await this.props.currentCompanyStore!.isFeatureEnabled(
      FeatureToggle.NEW_PRODUCT_SEARCH,
    )
    posStore.setUseSearchBar(useSearchBar)

    this.setState({ posStore })

    this.props.currentCompanyStore!.getGiftCardContracts()
    this.props.currentCompanyStore!.refreshBirchmountConfigs(company.id, location.id)

    await this.props.currentCompanyStore!.getWorldpayStatus()

    if (this.props.currentCompanyStore!.canUseWorldpay) {
      const worldpayStore = new WorldpayStore(company.id, location.id)

      worldpayStore.setCachedConnectionsAndSelectedTerminal()

      this.setState({ worldpayStore })
    }

    // check if feature flags are enabled
    const featureFlags = [
      this.props.currentCompanyStore?.getCannabinoidPerBatchStatus(),
      this.props.currentCompanyStore?.getProductDetailsPackageDateStatus(),
      this.props.currentCompanyStore?.getProductDetailsTerpenesAndCannbinoidsStatus(),
      this.props.currentCompanyStore?.getLoyaltyPointsForDiscount(),
    ]
    await Promise.all(featureFlags)
  }

  componentWillUnmount() {
    this.state.webSocketStore?.webSocket?.close()
  }

  render() {
    const company = this.props.currentCompanyStore!.company
    const location = this.state.location
    const device = this.state.device
    const shift = this.state.shift

    const centerStyle: React.CSSProperties = {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      color: THEME_COLORS.MONO.monodd,
    }

    if (!device) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Initializing device...</div>
          </div>
        </div>
      )
    } else if (!company) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading company...</div>
          </div>
        </div>
      )
    } else if (shift === undefined) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading shift...</div>
          </div>
        </div>
      )
    } else if (!location) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading location...</div>
          </div>
        </div>
      )
    } else if (!this.state.posStore) {
      return (
        <div style={centerStyle}>
          <div>
            <LoadingAnimation />
            <div>Loading POS...</div>
          </div>
        </div>
      )
    } else if (this.state.posStore) {
      const posStore = this.state.posStore!
      const worldpayStore = this.state.worldpayStore
      const webSocketStore = this.state.webSocketStore

      if (shift) {
        return (
          <Provider
            posStore={posStore}
            worldpayStore={worldpayStore}
            webSocketStore={webSocketStore}
          >
            <>
              <Helmet>
                <title>POS</title>
                <meta name='page-loaded' content='true' />
              </Helmet>
              <div style={{ height: '100vh' }}>
                <div style={{ height: '56px' }}>
                  <POSNavbar />
                </div>
                <div style={{ width: `calc(100vw)`, height: 'calc(100vh - 56px)' }}>
                  {this.props.children}
                </div>
              </div>
            </>
          </Provider>
        )
      } else {
        return (
          <Provider
            posStore={posStore}
            worldpayStore={worldpayStore}
            webSocketStore={webSocketStore}
          >
            <>
              <Helmet>
                <title>POS | Shift start</title>
                <meta name='page-loaded' content='true' />
              </Helmet>
              <ShiftLogin location={this.props.location} />
            </>
          </Provider>
        )
      }
    } else {
      return (
        <div style={centerStyle}>
          <div>
            <div>Error</div>
          </div>
        </div>
      )
    }
  }
}
